<template>
	<div class="box">
		<img :src="icon" class="icon" />
		<p class="text">{{ text }}</p>
	</div>
</template>

<script>
export default {
	props: {
		icon: {
			type: String,
			default: '',
		},
		text: {
			type: String,
			default: '',
		},
	},
}
</script>

<style lang="scss" scoped>
.box {
	padding-top: 3rem;
}
.icon {
	@include margin_center;
	display: block;
	width: 4rem;
}
.text {
	font-size: 0.37rem;
	line-height: 0.53rem;
	color: #919499;
	text-align: center;
	margin-bottom: 0.7rem;
}
</style>
