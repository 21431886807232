<template>
  <div v-if="pageLoadingComplete">
    <commonHeader :show-dialogue="true" />
    <template v-if="addrList.length <= 0">
      <page-default text="您还没有收货地址哦~" :icon="icon"></page-default>
      <div class="button button-add">
        <van-button round type="primary" @click="toEditAddress()">新增地址
        </van-button>
      </div>
    </template>
    <template v-else>
      <div class="container background-white">
        <van-radio-group v-model="selectAddressId">
          <div class="address-card" v-for="item in addrList" :key="item.value">
            <van-radio :name="item.id" checked-color="#FF4D4D" @click="toggle(item.id)">
              <template #icon="props">
                <img
                  :src="props.checked ? require('@/assets/images/common/select.png') : require('@/assets/images/common/noselect.png')"
                  class="select-icon">
              </template>
            </van-radio>

            <div class="address-card-content" @click="toggle(item.id)">
              <div class="address-edit">
                <p class="name">
                  <span>{{ item.consignee }}</span><span>{{ item.phone }}</span>
                  <label v-if="item.default === 1">默认</label>
                </p>
                <p class="text">
                  {{ item.text }}
                  {{ item.address }}
                </p>
              </div>
            </div>
            <img src="@/assets/images/common/edit.png" class="icon" @click="to(item.id)" />
          </div>
        </van-radio-group>
      </div>
    </template>

    <footerFixed :dialogue-show="false" v-if="addrList.length > 0">
      <pageBottomButton color="red" buttonType="text" text="新建收货地址" @click="to" />
    </footerFixed>
  </div>
</template>

<script>
import pageDefault from '@/components/page-empty-default'

import {apiAddressList} from '@/api/address'
import pageBottomButton from '@/components/page-bottom-button'
import footerFixed from '@/components/page-footer-fixed'
import commonMixins from "@/mixins/common";

export default {
  components: {
    pageDefault,
    pageBottomButton,
    footerFixed,
  },
  mixins: [commonMixins],
  data() {
    return {
      icon: require('@/assets/images/empty/no_address.png'),
      // 地址列表
      addrList: [],

      selectAddressId: this.$store.state.address.select_id,

      pageLoadingComplete: false,
    }
  },
  mounted() {
   this.$util.setShare()
  },
  methods: {
    toggle(key) {
      this.selectAddressId = key
      this.$store.dispatch('setAddressSelectId', key)
      this.$router.go(-1) //返回上一层
    },
    toEditAddress() {
      let query = {}
      this.$router.push({
        path: '/address/edit',
        query,
      })
    },
    // 获取地址列表
    getAddressList() {
      apiAddressList({}).then((res) => {
        if (res.data) {
          this.addrList = res.data
        }

        let selectId = this.selectAddressId

        if (selectId === 0) {
          selectId = res.data[0]?.id
          this.$store.dispatch('setAddressSelectId', selectId)
          this.selectAddressId = selectId
        }

        this.pageLoadingComplete = true
      })
    },
    to(id) {
      let query = {}
      if (id) {
        query.id = id
      }
      this.$router.push({path: '/address/edit', query})
    },
  },
  created() {
    this.getAddressList()
  },
}
</script>

<style lang="scss" scoped>
.button {
  &-add {
    text-align: center;

    .van-button {
      width: 3.84rem;
      height: 0.96rem;
    }
  }
}

.address {
  &-list {
    background-color: #fff;
  }

  &-card {
    @include flex_between_center;
    padding: 0.4rem 0;

    &+& {
      border-top: 0.01rem solid #ebecf0;
    }

    &-content {
      flex: 1;
      padding-left: 0.43rem;

      box-sizing: border-box;
    }

    .name {
      font-size: 0.43rem;
      font-weight: bold;
      color: #18191a;
      margin-bottom: 0.3rem;
      max-width: 6.8rem;

      >span+span {
        margin-left: 0.45rem;
      }

      label {
        width: 0.85rem;
        height: 0.43rem;
        line-height: 0.43rem;
        border: 0.01rem solid #e5b66e;
        border-radius: 0.05rem;
        font-size: 0.29rem;
        text-align: center;
        color: #e5b66e;
        display: inline-block;
        box-sizing: border-box;
        margin-left: 0.1rem;
        transform: translateY(-0.05rem);
      }
    }

    .text {
      max-width: 6.8rem;
      font-size: 0.32rem;
      line-height: 0.45rem;
      color: #333333;
    }

    .icon {
      width: 0.4rem;
    }
  }
}

.bottom-fixed-button {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  @include maxWidth();

  .button {
    margin: 0.64rem 0;
  }
}

.select-icon {
  width: .53rem;
  height: .53rem;
}
</style>
